import React, { useState } from 'react';
import '../css/doctor.css'
import { useNavigate } from 'react-router-dom';
import doctorpic1 from './image/doctorpic1.png';
import doctorpic2 from './image/doctorpic2.png';
import doctorpic3 from './image/doctorpic3.png';
import doctorpic4 from './image/doctorpic4.png';
import doctorpic5 from './image/doctorpic5.png';
import doctorpic6 from './image/doctorpic6.png';
import doctorpic7 from './image/doctorpic7.png';
import doctorpic8 from './image/doctorpic8.png';

const Doctors = () => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  
  const specialities = [
    'Skin',
    'Aesthetic And Reconstructive Surgery',
    'Allergy',
    'Anaesthesia',
    'Arthroscopy & Sports Injury',
    'Audiology',
    'Bariatric Surgery / Metabolic',
    'Bone Marrow Transplant',
    'Breast Cancer',
    'Cancer Care / Oncology',
    'Cardiac Anesthesia',
    'Cardiac Electrophysiology-Pacemaker',
    'Cardiac Sciences',
    'Cardiac Surgery (CTVS)',
    'Cardiology',
    'Child Development Clinic',
    'Clinical Psychology',
    'Critical Care',
    'Dental Care',
    'Department of General Surgery and Robotics',
    'Dermatology',
    'Diabesity Clinic',
    'Diabetes Educator',
    'Dietetics',
    'Emergency & Trauma',
    'Endocrinology & Diabetes',
    'Endodontist & Cosmetic Dentist',
    'ENT(Ear Nose Throat)',
    'Eye Care / Ophthalmology',
    'Foetal Medicine',
    'Gastro Intestinal & Hepatopancreatobiliary Surgical Oncology',
    'Gastroenterology, Hepatology & Endoscopy',
    'Gastrointestinal & Hepatobiliary Oncology',
    'Gastrointestinal Surgery',
    'General Surgery',
    'Gynaecologic Laparoscopy',
    'Gynecologic Oncology',
    'Haematology (Hematology)',
    'Hair Transplant',
    'Head & Neck Oncology',
    'Hemato-Pathology',
    'Hematology Oncology',
    'Infertility & IVF',
    'Internal Medicine',
    'Interventional Cardiology',
    'Interventional Neurology',
    'Interventional Radiology',
    'Kidney Transplant',
    'Laboratory Medicine / Pathology',
    'Laparoscopic / Minimal Access Surgery',
    'Liver Transplant and Biliary Sciences',
    'Lung Transplant',
    'Maxillofacial Surgery and Implantology',
    'Medical Oncology',
    'Mental Health And Behavioural Sciences',
    'Molecular Oncology & Cancer Genetics',
    'Musculoskeletal Oncology',
    'Musculoskeletal Surgical Oncology',
    'Neonatology',
    'Nephrology',
    'Neuro Oncology',
    'Neurology',
    'Neurosciences',
    'Neurosurgery',
    'Nuclear Medicine',
    'Nutrition And Dietetics',
    'Obstetrics And Gynaecology',
    'Oncology Reconstructive Surgery',
    'Orthodontics & Dentofacial Orthopaedics',
    'Orthopaedics & Joint Replacement',
    'Paediatric (Ped) Cardiac Surgery',
    'Paediatric (Ped) Cardiology',
    'Paediatric (Ped) Endocrinology',
    'Paediatric (Ped) Gastroenterology',
    'Paediatric (Ped) Hematology',
    'Paediatric (Ped) Immunology',
    'Paediatric (Ped) Infectious Diseases',
    'Paediatric (Ped) Intensive Care',
    'Paediatric (Ped) Nephrology',
    'Paediatric (Ped) Neurology',
    'Paediatric (Ped) Neurosurgery',
    'Paediatric (Ped) Oncology',
    'Paediatric (Ped) Orthopaedics',
    'Paediatric (Ped) Pulmonology',
    'Paediatric (Ped) Surgery',
    'Paediatric (Ped) Urology',
    'Paediatric (Ped)/Medical Oncology',
    'Paediatric Haematology',
    'Paediatric Liver Transplant',
    'Paediatrics (Ped)',
    'Pain Management',
    'Palliative Care',
    'Periodontology',
    'Physiotherapy & Rehabilitation Medicine',
    'Podiatry',
    'Preventive Health Check',
    'Psychiatry',
    'Psycho-oncology',
    'Pulmonology',
    'Radiation Oncology',
    'Radiology',
    'Rheumatology',
    'Robotic Surgery',
    'Speech Therapy',
    'Spine Surgery',
    'Surgical Oncology',
    'Thoracic Oncology',
    'Thoracic Oncology Surgery',
    'Thoracic Surgery',
    'Transfusion Medicine',
    'Uro-Oncology',
    'Urology',
    'Vascular Surgery',
  ];

  const toggleDropdown = () => setIsOpen(!isOpen);
  
  const filteredSpecialities = specialities.filter(speciality =>
    speciality.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="doctors-container">
      <div className="search-and-filters">
        <div className="group">
          <svg className="doctor-search-icon" aria-hidden="true" viewBox="0 0 24 24">
            <g><path d="M21.53 20.47l-3.66-3.66C19.195 15.24 20 13.214 20 11c0-4.97-4.03-9-9-9s-9 4.03-9 9 4.03 9 9 9c2.215 0 4.24-.804 5.808-2.13l3.66 3.66c.147.146.34.22.53.22s.385-.073.53-.22c.295-.293.295-.767.002-1.06zM3.5 11c0-4.135 3.365-7.5 7.5-7.5s7.5 3.365 7.5 7.5-3.365 7.5-7.5 7.5-7.5-3.365-7.5-7.5z"></path></g>
          </svg>
          <input placeholder="Search by Doctor" type="search" className="input-search"/>
        </div>

        <div>
          <p className='doctor-filters'>Filters</p>
          <div className="dropdown-container">
            <div className="dropdown-header" onClick={toggleDropdown}>
              <span>Speciality</span>
              <span className="arrow">{isOpen ? '▲' : '▼'}</span>
            </div>
            {isOpen && (
              <div className="dropdown-content">
                <input
                  type="text"
                  placeholder="Search"
                  className="search-box"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
                <ul className='search-item'>
                  {filteredSpecialities.map((speciality, index) => (
                    <li className='search-items' key={index}>
                      <label>
                        <input type="checkbox" /> {speciality}
                      </label>
                    </li>
                  ))}
                  {filteredSpecialities.length === 0 && (
                    <li className='no-result'>No results found</li>
                  )}
                </ul>
              </div>
            )}
          </div>
        </div>
      </div>

      <div>
        <p className='doctor-Available'>Doctors Available</p>
      </div>

      <div className="doctors-list">

        <div className="doctor-card">
          <div className="doctor-info">
            <img className='doctor-image' src={doctorpic1} alt="Dr Ruchi Goyal" />
            <div className="doctor-details">
              <p className='doctor-name'>Dr Ruchi Goyal</p>
              <p className='doctor-title'>Vice Chairman - Medical Oncology (Breast, Gynaecology, Thoracic)</p>
              <p className='doctor-specialties'>Cancer Care / Oncology, Gynecologic Oncology, Thoracic Oncology, Medical Oncology, Breast Cancer</p>
            </div>
          </div>
          <div className="doctor-metadata">
            <span><strong>Experience:</strong> 12+ Years</span>
            <span><strong>Gender:</strong> Female</span>
          </div>
          <button className="bookappointmentbutton" onClick={() => navigate('/bookanappointmentform')}>Book an Appointment</button>
        </div>

        <div className="doctor-card">
          <div className="doctor-info">
            <img className='doctor-image' src={doctorpic2} alt="Dr Swati Mishra" />
            <div className="doctor-details">
              <p className='doctor-name'>Dr Swati Mishra</p>
              <p className='doctor-title'>Director - Nephrology</p>
              <p className='doctor-specialties'>Nephrology, Kidney Transplant</p>
            </div>
          </div>
          <div className="doctor-metadata">
            <span><strong>Experience:</strong> 14+ Years</span>
            <span><strong>Gender:</strong> Female</span>
          </div>
          <button className="bookappointmentbutton" onClick={() => navigate('/bookanappointmentform')}>Book an Appointment</button>
        </div>

        <div className="doctor-card">
          <div className="doctor-info">
            <img className='doctor-image' src={doctorpic3} alt="Dr. Naveen Kumar" />
            <div className="doctor-details">
              <p className='doctor-name'>Dr. Naveen Kumar</p>
              <p className='doctor-title'>Principal Director - Interventional Cardiology</p>
              <p className='doctor-specialties'>Cardiac Sciences, Cardiology, Interventional Cardiology, Cardiac Electrophysiology-Pacemaker</p>
            </div>
          </div>
          <div className="doctor-metadata">
            <span><strong>Experience:</strong> 8+ Years</span>
            <span><strong>Gender:</strong> Male</span>
          </div>
          <button className="bookappointmentbutton" onClick={() => navigate('/bookanappointmentform')}>Book an Appointment</button>
        </div>

        <div className="doctor-card">
          <div className="doctor-info">
            <img className='doctor-image' src={doctorpic4} alt="Dr. Sunil Chaudhary" />
            <div className="doctor-details">
              <p className='doctor-name'>Dr. Sunil Chaudhary</p>
              <p className='doctor-title'>Principal Director, Chief Surgeon & Head - Neonatal & Congenital Heart Surgery</p>
              <p className='doctor-specialties'>Cardiac Sciences, Paediatrics (Ped), Paediatric (Ped) Cardiac Surgery</p>
            </div>
          </div>
          <div className="doctor-metadata">
            <span><strong>Experience:</strong> 11+ Years</span>
            <span><strong>Gender:</strong> Male</span>
          </div>
          <button className="bookappointmentbutton" onClick={() => navigate('/bookanappointmentform')}>Book an Appointment</button>
        </div>


        <div className="doctor-card">
          <div className="doctor-info">
            <img className='doctor-image' src={doctorpic5} alt="Dr. Keshav Dewan" />
            <div className="doctor-details">
              <p className='doctor-name'>Dr. Keshav Dewan</p>
              <p className='doctor-title'>Principal Director (Critical Care) & Director (Internal Medicine)</p>
              <p className='doctor-specialties'>Internal Medicine, Critical Care</p>
            </div>
          </div>
          <div className="doctor-metadata">
            <span><strong>Experience:</strong> 10+ Years</span>
            <span><strong>Gender:</strong> Male</span>
          </div>
          <button className="bookappointmentbutton" onClick={() => navigate('/bookanappointmentform')}>Book an Appointment</button>
        </div>

  
        <div className="doctor-card">
          <div className="doctor-info">
            <img className='doctor-image' src={doctorpic6} alt="Dr. Anushka Das" />
            <div className="doctor-details">
              <p className='doctor-name'>Dr. Anushka Das</p>
              <p className='doctor-title'>Director & HOD - Ophthalmology</p>
              <p className='doctor-specialties'>Eye Care / Ophthalmology</p>
            </div>
          </div>
          <div className="doctor-metadata">
            <span><strong>Experience:</strong> 15+ Years</span>
            <span><strong>Gender:</strong> Female</span>
          </div>
          <button className="bookappointmentbutton" onClick={() => navigate('/bookanappointmentform')}>Book an Appointment</button>
        </div>




        <div className="doctor-card">
          <div className="doctor-info">
            <img className='doctor-image' src={doctorpic7} alt="Dr. Saket Bhardwaj" />
            <div className="doctor-details">
              <p className='doctor-name'>Dr. Saket Bhardwaj</p>
              <p className='doctor-title'>Director - Surgical Oncology (Head & Neck)</p>
              <p className='doctor-specialties'>Surgical Oncology, Cancer Care / Oncology, Head & Neck Oncology</p>
            </div>
          </div>
          <div className="doctor-metadata">
            <span><strong>Experience:</strong> 13+ Years</span>
            <span><strong>Gender:</strong> Male</span>
          </div>
          <button className="bookappointmentbutton" onClick={() => navigate('/bookanappointmentform')}>Book an Appointment</button>
        </div>


        <div className="doctor-card">
          <div className="doctor-info">
            <img className='doctor-image' src={doctorpic8} alt="Dr. Kanika Gupta" />
            <div className="doctor-details">
              <p className='doctor-name'>Dr. Kanika Gupta</p>
              <p className='doctor-title'>Director Surgical Oncology (Gynae & Robotic Surgery)</p>
              <p className='doctor-specialties'>Surgical Oncology, Cancer Care / Oncology, Gynecologic Oncology, Robotic Surgery, Gynaecologic Laparoscopy</p>
            </div>
          </div>
          <div className="doctor-metadata">
            <span><strong>Experience:</strong> 8+ Years</span>
            <span><strong>Gender:</strong> Female</span>
          </div>
          
          <button className="bookappointmentbutton" onClick={() => navigate('/bookanappointmentform')}>Book an Appointment</button>
        </div>




      </div>

    </div>
  );
}

export default Doctors;










