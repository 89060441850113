import React, { useEffect, useState } from 'react';
import axios from 'axios';
import '../css/doctorappointmentlist.css'

const Doctorappointmentlist = () => {
  const [userlist, setuserlist] = useState([]);

  useEffect(() => {
    userlistData()
  }, []);
  
  const userlistData = () => {
    return axios.get('http://localhost:8080/appointment/appointmentlist/')
      .then((response) => {
        setuserlist(response.data.message)
      })
      .catch((error) => {
        console.error("Error fetching appointment list:", error);
      });
  }

  return (
    <div className="doctor-patient-list">
      <div className="table-container">
      <table className='patient-details'>
        <thead>
          <tr>
            <th>Id</th>
            <th>Full Name</th>
            <th>Age</th>
            <th>Gender</th>
            <th>Mobile</th>
            <th>Date</th>
            <th>Address</th>
          </tr>
        </thead>
        <tbody>
          {userlist && userlist.map((user) => (
            <tr key={user.id}>
              <td data-label="Id">{user.id}</td>
              <td data-label="Full Name">{user.fullname}</td>
              <td data-label="Age">{user.age}</td>
              <td data-label="Gender">{user.gender}</td>
              <td data-label="Mobile">{user.mobile}</td>
              <td data-label="Date">{user.date}</td>
              <td data-label="Address">{user.address}</td>
            </tr>
          ))}
        </tbody>
      </table>
      </div>
    </div>
  )
}

export default Doctorappointmentlist;