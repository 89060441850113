import React, { useState } from "react";
import "../css/patient.css";
import { useNavigate } from 'react-router-dom';
import patientimage from "./image/patientimage.png";
import axios from "axios";
import Swal from 'sweetalert2';

const Patient = () => {
  const navigate = useNavigate();

  const [mobile, mobileSet] = useState('');
  const [password, passwordSet] = useState('');
  const [newPassword, newPasswordSet] = useState('');
  const [errors, setErrors] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [isResettingPassword, setIsResettingPassword] = useState(false);

  
  const mobileHandler = (event) => {
    mobileSet(event.target.value);
  }

  const passwordHandler = (event) => {
    passwordSet(event.target.value);
  }

  const newPasswordHandler = (event) => {
    newPasswordSet(event.target.value);
  }

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  }

  const validateForm = () => {
    let formErrors = {};

    if (!mobile) {
      formErrors.mobile = "Mobile number is required";
    } else if (!/^\d{10}$/.test(mobile)) {
      formErrors.mobile = "Please enter a valid 10-digit mobile number";
    }

    if (!isResettingPassword && !password) {
      formErrors.password = "Password is required";
    } else if (isResettingPassword && !newPassword) {
      formErrors.newPassword = "New password is required";
    } else if ((isResettingPassword ? newPassword : password).length < 4) {
      formErrors[isResettingPassword ? 'newPassword' : 'password'] = "Password must be at least 4 characters long";
    }

    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  }

  const submitHandler = (event) => {
    event.preventDefault();
    
    if (validateForm()) {
      let data = { mobile: mobile };
      if (isResettingPassword) {
        data.newPassword = newPassword;
      } else {
        data.password = password;
      }

      const url = isResettingPassword ? 'http://localhost:8080/auth/resetpassword/' : 'http://localhost:8080/auth/login/';

      axios.post(url, data)
        .then((response) => {
          if (response.data.status === 200) {
            if (isResettingPassword) {
              Swal.fire({
                icon: "success",
                title: "Success",
                text: "Password reset successfully. Please login with your new password.",
              });
              setIsResettingPassword(false);
              newPasswordSet('');
            } else {
              localStorage.setItem('id', response.data.message.patientdata[0].id);
              localStorage.setItem('mobile', response.data.message.patientdata[0].mobile);
              navigate('/patientdashboard');
            }
            mobileSet('');
            passwordSet('');
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: response.data.message,
            });
          }
        })
        .catch((error) => {
          console.error("Login error:", error);
            Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Login failed. Please check your credentials and try again.",
            
          });
        });
        
    }
  }


  return (
    <div className="patient-container">
      <form name="loginform" onSubmit={submitHandler}>
        <div className="patientloginbox">
          <div className="patientformbox">
            <h3>{isResettingPassword ? "Reset Password" : "Login to Patient"}</h3>
            <div className="patient-input">
              <i className="fa fa-mobile" aria-hidden="true"></i>
              <input 
                type="text" 
                placeholder="Enter your mobile" 
                name="mobile" 
                onChange={mobileHandler}
                id="mobile" 
                value={mobile}
              />
              {errors.mobile && <div className="error">{errors.mobile}</div>}
            </div>
            {!isResettingPassword ? (
              <div className="patient-input">
                <i className="fa fa-lock" aria-hidden="true"></i>
                <input 
                  type={showPassword ? "text" : "password"}
                  placeholder="Enter your password" 
                  name="password" 
                  onChange={passwordHandler}
                  id="password" 
                  value={password}
                />
                <i 
                  className={`fa ${showPassword ? "fa-eye-slash" : "fa-eye"}`}
                  aria-hidden="true"
                  onClick={togglePasswordVisibility}
                ></i>
                {errors.password && <div className="error">{errors.password}</div>}
              </div>
            ) : (
              <div className="patient-input">
                <i className="fa fa-lock" aria-hidden="true"></i>
                <input 
                  type={showPassword ? "text" : "password"}
                  placeholder="Enter new password" 
                  name="newPassword" 
                  onChange={newPasswordHandler}
                  id="newPassword" 
                  value={newPassword}
                />
                <i 
                  className={`fa ${showPassword ? "fa-eye-slash" : "fa-eye"}`}
                  aria-hidden="true"
                  onClick={togglePasswordVisibility}
                ></i>
                {errors.newPassword && <div className="error">{errors.newPassword}</div>}
              </div>
            )}
            <a className="patientforgot" onClick={() => setIsResettingPassword(!isResettingPassword)}>
              {isResettingPassword ? "Back to Login" : "Forgot password ?"}
            </a>
            <div className="patientsignup">
              Don't have an account ? <a>Signup</a>
            </div>
            <section>
              <button className="patientloginbtn" type="submit">{isResettingPassword ? "Reset Password" : "Login"}</button>
            </section>
            {errors.form && <div className="error">{errors.form}</div>}
            <div className="or">Or</div>
            <section>
              <button className="doctorbtn" type="button" onClick={() => navigate('/dashboard')}>As a Doctor</button>
            </section>
          </div>
          <div className="patientloginimg">
            <img src={patientimage} alt="" />
          </div>
        </div>
      </form>
    </div>
  );
}

export default Patient;



























/*


import "../css/patient.css";
import { useNavigate } from 'react-router-dom';
import patientimage from "./image/patientimage.png";
import { useState } from "react";
import axios from "axios";
import Swal from 'sweetalert2';

const Patient = () => {
  const navigate = useNavigate();

  const [mobile, mobileSet] = useState('');
  const [password, passwordSet] = useState('');
  const [errors, setErrors] = useState({});

  const mobileHandler = (event) => {
    mobileSet(event.target.value);
  }

  const passwordHandler = (event) => {
    passwordSet(event.target.value);
  }

  const validateForm = () => {
    let formErrors = {};

    if (!mobile) {
      formErrors.mobile = "Mobile number is required";
    } else if (!/^\d{10}$/.test(mobile)) {
      formErrors.mobile = "Please enter a valid 10-digit mobile number";
    }

    if (!password) {
      formErrors.password = "Password is required";
    } else if (password.length < 4) {
      formErrors.password = "Password must be at least 6 characters long";
    }

    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  }

  const submitHandler = (event) => {
    event.preventDefault();
    
    if (validateForm()) {
      let patientData = { mobile: mobile, password: password };
      axios.post('http://localhost:8080/auth/login/', patientData)
        .then((response) => {
          console.log(response.data.message.patientdata[0]);
        if (response.data) {
        localStorage.setItem('id', response.data.message.patientdata[0].id);
        localStorage.setItem('mobile', response.data.message.patientdata[0].mobile);
        navigate('/patientdashboard');
        } else {
         navigate('/dashboard');
     }
        })
        .catch((error) => {
          console.error("Login error:", error);
        
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Login failed. Please check your credentials and try again.",
            
          });
        });
        
    }
  }

  return (
    <>
      <form name="loginform" onSubmit={submitHandler}>
        <div className="patientloginbox">
          <div className="patientformbox">
            <h3>Login to Patient</h3>
            <div className="patient-input">
              <i className="fa fa-mobile" aria-hidden="true"></i>
              <input 
                type="text" 
                placeholder="Enter your mobile" 
                name="mobile" 
                onChange={mobileHandler}
                id="mobile" 
                value={mobile}
              />
              {errors.mobile && <div className="error">{errors.mobile}</div>}
            </div>
            <div className="patient-input">
              <i className="fa fa-lock" aria-hidden="true"></i>
              <input 
                type="password" 
                placeholder="Enter your password" 
                name="password" 
                onChange={passwordHandler}
                id="password" 
                value={password}
              />
              {errors.password && <div className="error">{errors.password}</div>}
            </div>
            <a className="patientforgot" href="#">Forgot password?</a>
            <div className="patientsignup">
              Don't have an account? <a>Signup now</a>
            </div>
            <section>
              <button className="patientloginbtn" type="submit">Login</button>
            </section>
            {errors.form && <div className="error">{errors.form}</div>}
            <div className="or">Or</div>
            <section>
              <button className="doctorbtn" type="button" onClick={() => navigate('/dashboard')}>As a Doctor</button>
            </section>
          </div>
        </div>
        <figure className="patientimage">
          <img src={patientimage} alt="" />
        </figure>
      </form>
    </>
  )
}

export default Patient;



*/