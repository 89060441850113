import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FaCamera, FaFilePrescription } from "react-icons/fa";
import "../css/drdashheader.css";

const Drdashheader = () => {
  const navigate = useNavigate();
  const [image, setImage] = useState(null);
  const [preview, setPreview] = useState(null);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  useEffect(() => {
    const savedImage = localStorage.getItem("profilePicture");
    if (savedImage) {
      setPreview(savedImage);
    }

    const handleResize = () => {
      if (window.innerWidth > 1024) {
        setIsSidebarOpen(false);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result);
        setPreview(reader.result);
        localStorage.setItem("profilePicture", reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleLogout = () => {
    localStorage.clear();
    navigate('/dashboard');
  };

  return (
    <>
      <div className={`sidebar ${isSidebarOpen ? "open" : ""}`}>
        <div className="profile-picture-container">
          <div className="profile-picture">
            {preview ? (
              <img src={preview} alt="Profile" />
            ) : (
              <div className="default-picture">No Image</div>
            )}
          </div>
          <input
            type="file"
            accept="image/*"
            onChange={handleImageUpload}
            style={{ display: "none" }}
            id="fileInput"
          />
          <label htmlFor="fileInput" className="upload-button">
            <FaCamera />
          </label>
        </div>
        <nav>
          <a className="active" onClick={() => navigate("/drdashboard")}>
            <span className="icon-0"><i className="ri-layout-grid-line"></i></span>
            <span>Dashboard</span>
          </a>
          <a onClick={() => navigate("/prescriptionform")}>
            <span className="icon-1"><FaFilePrescription /></span>
            <span>Prescription</span>
          </a>
          <a onClick={() => navigate("/doctorpatientlist")}>
            <span className="icon-2"><i className="ri-user-2-line"></i></span>
            <span>Patient</span>
          </a>
          <a onClick={() => navigate("/doctorappointmentlist")}>
            <span className="icon-3"><i className="ri-calendar-2-line"></i></span>
            <span>Appointment</span>
          </a>
          <a onClick={() => navigate("")}>
            <span className="icon-4"><i className="ri-line-chart-line"></i></span>
            <span>Payments</span>
          </a>
          <a href="#">
            <span className="icon-5"><i className="ri-settings-3-line"></i></span>
            <span>Setting</span>
          </a>
          <a onClick={handleLogout}>
            <span className="icon-6"><i className="ri-logout-box-r-line"></i></span>
            <span>Logout</span>
          </a>
        </nav>
      </div>

      <div className="main-content">
        <header>
          <button className="toggle-sidebar" onClick={toggleSidebar}>
            <i className={`fas ${isSidebarOpen ? "fa-times" : "fa-bars"}`}></i>
          </button>
          <section className="doctorsearchbox">
<div className="search--notification--profile">
  <div className="search">
    <input type="text" placeholder="Search" />
    <button>
      <i className="ri-search-2-line"></i>
    </button>
  </div>
  <div className="notification--profile">
    <div className="picon bell">
      <i className="ri-notification-2-line"></i>
    </div>
    <div className="picon chat">
      <i className="ri-wechat-2-line"></i>
    </div>
  </div>
</div>
</section> 
        </header>
       
      </div>
    </>
  );
};

export default Drdashheader;










