import React, { useState } from "react";
import "../css/contactus.css";
import contactimg from "./image/contactimg.png";
import locationcontact from "./image/locationcontact.png";
import callcontact from "./image/callcontact.png";
import mailcontact from "./image/mailcontact.png";
import axios from "axios";
import Swal from 'sweetalert2';

const Contactus = () => {
  const [name, nameSet] = useState('');
  const [email, emailSet] = useState('');
  const [phone, phoneSet] = useState('');
  const [message, messageSet] = useState('');
  const [errors, setErrors] = useState({});
 

  const nameHandler = (event) => nameSet(event.target.value);
  const emailHandler = (event) => emailSet(event.target.value);
  const phoneHandler = (event) => phoneSet(event.target.value);
  const messageHandler = (event) => messageSet(event.target.value);

  const validateForm = () => {
    let tempErrors = {};
    if (!name.trim()) tempErrors.name = "Name is required";
    if (!email.trim()) tempErrors.email = "Email is required";
    else if (!/\S+@\S+\.\S+/.test(email)) tempErrors.email = "Email is invalid";
    if (!phone.trim()) tempErrors.phone = "Phone is required";
    else if (!/^\d{10}$/.test(phone)) tempErrors.phone = "Phone number should be 10 digits";
    if (!message.trim()) tempErrors.message = "Message is required";
    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const submitHandler = (event) => {
    event.preventDefault();
 

    if (validateForm()) {
      let contactusdata = { name, email, phone, message };
      axios.post('http://localhost:8080/contactus/contactus/', contactusdata)
        .then((response) => {
          console.log(response);
          
         

          // Clear form fields after successful submission
          nameSet('');
          emailSet('');
          phoneSet('');
          messageSet('');

          // Show success message
          Swal.fire({
            icon: 'success',
            title: 'Submit',
            text: 'Your message was submitted successfully! We will get back to you soon.',
            confirmButtonText: 'OK',
            confirmButtonColor: '#0066ff'
          });
        })
        .catch((error) => {
          console.error("Error submitting form:", error);

          // Show error message
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'There was an error submitting your message. Please try again.',
            confirmButtonText: 'OK',
            confirmButtonColor: '#0066ff'
          });
        });
    }
  };

  return (
    <div className="contact-page">
      <section className="hero-section">
        <img className="contactimage" src={contactimg} alt="Contact Us" />
      </section>

      <div className="content-wrapper">
        <section className="info-section">
          <div className="contactbox">
            <h2 className="help">HOW WE CAN HELP YOU?</h2>
            <div className="info-text">
              <p>Welcome to Medical Health care! Ensuring your health is our priority.</p>
              <p>Schedule your consultation with our expert physicians and specialists effortlessly. Simply choose your preferred date, time, and department.</p>
              <p>Our dedicated team will confirm your appointment promptly.</p>
            </div>
            <div className="contact-details">
              <div className="contact-item">
                <img className="contacticon" src={locationcontact} alt="Location" />
                <div>
                  <p>Delhi 110094</p>
                  
                </div>
              </div>
              <div className="contact-item">
                <img className="contacticon" src={callcontact} alt="Call" />
                <p>+91-120-95828448</p>
              </div>
              <div className="contact-item">
                <img className="contacticon" src={mailcontact} alt="Email" />
                <p>info@medicalhealthcare.com</p>
              </div>
            </div>
          </div>
        </section>

        <section className="form-section">
          <h2 className="quick">Quick Enquiry</h2>
          <form className="contactform" onSubmit={submitHandler}>
            <div className="input_box">
              <input
                type="text"
                placeholder="Name"
                name="name"
                value={name}
                onChange={nameHandler}
                id="name"
              />
              {errors.name && <div className="error">{errors.name}</div>}
            </div>
            <div className="input_box">
              <input
                type="text"
                placeholder="E-mail"
                name="email"
                value={email}
                onChange={emailHandler}
                id="email"
              />
              {errors.email && <div className="error">{errors.email}</div>}
            </div>
            <div className="input_box">
              <input
                type="text"
                placeholder="Phone"
                name="phone"
                value={phone}
                onChange={phoneHandler}
                id="phone"
              />
              {errors.phone && <div className="error">{errors.phone}</div>}
            </div>
            <div className="input_message_box">
              <textarea
                className="contactus-message"
                placeholder="Message"
                name="message"
                value={message}
                onChange={messageHandler}
                id="message"
              />
              {errors.message && <div className="error">{errors.message}</div>}
            </div>
            <button className="contactbutton" type="submit">Send Now</button>
          </form>
        </section>
      </div>
    </div>
  );
};

export default Contactus;
