import React, { useEffect, useState } from "react";
import axios from "axios";
import Swal from 'sweetalert2';
import "../css/prescriptionform.css";

const Patientregistration = () => {
  const [formData, setFormData] = useState({
    name: '',
    age: '',
    gender: '',
    mobile: '',
    symptoms: '',
    treatment: '',
  });
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [errors, setErrors] = useState({});
  const [doctorInfo, setDoctorInfo] = useState({
    doctor_id: '',
    doctor_mobile: '',
    doctor_name: '',
    doctor_email: '',
  });

  useEffect(() => {
    setDoctorInfo({
      doctor_id: localStorage.getItem('id'),
      doctor_mobile: localStorage.getItem('doctor_mobile'),
      doctor_name: localStorage.getItem('doctor_name'),
      doctor_email: localStorage.getItem('doctor_email'),
    });
  }, []);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const acceptTermsHandler = (event) => {
    setAcceptTerms(event.target.checked);
  };

  const validateForm = () => {
    let tempErrors = {};
    if (!formData.name.trim()) tempErrors.name = "Name is required";
    if (!formData.age.trim()) tempErrors.age = "Age is required";
    else if (isNaN(formData.age) || formData.age < 0 || formData.age > 120) tempErrors.age = "Please enter a valid age";
    if (!formData.gender) tempErrors.gender = "Gender is required";
    if (!formData.mobile.trim()) tempErrors.mobile = "Mobile number is required";
    else if (!/^\d{10}$/.test(formData.mobile)) tempErrors.mobile = "Please enter a valid 10-digit mobile number";
    if (!formData.symptoms.trim()) tempErrors.symptoms = "Symptoms are required";
    if (!formData.treatment.trim()) tempErrors.treatment = "Treatment is required";
    if (!acceptTerms) tempErrors.acceptTerms = "You must accept the terms and conditions";
    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const submitHandler = (event) => {
    event.preventDefault();
    if (validateForm()) {
      let patientregistrationData = {
        ...formData,
        password: '1234',
        ...doctorInfo,
      };
      axios.post('http://localhost:8080/auth/patientregistration/', patientregistrationData)
        .then((response) => {
          console.log(response);
          setFormData({
            name: '',
            age: '',
            gender: '',
            mobile: '',
            symptoms: '',
            treatment: '',
          });
          setAcceptTerms(false);
          Swal.fire({
            icon: 'success',
            title: 'Success',
            text: 'Your Registration was submitted successfully!',
            confirmButtonText: 'OK',
            confirmButtonColor: '#0066ff'
          });
        })
        .catch((error) => {
          console.error("Error submitting form:", error);
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'There was an error submitting your message. Please try again.',
            confirmButtonText: 'OK',
            confirmButtonColor: '#0066ff'
          });
        });
    }
  };

  return (
    <div className="patientregistration-container">
      <form name='patientregistrationform' onSubmit={submitHandler} className="patientregistrationbox">
        <h3>Prescription Form</h3>
        <div className="form-row">
          <div className="patient-input1">
            <input
              type="text"
              placeholder="Name"
              name='name'
              value={formData.name}
              onChange={handleInputChange}
              id='name'
            />
            {errors.name && <div className="error">{errors.name}</div>}
          </div>
          <div className="patient-input1">
            <input
              type="number"
              placeholder="Age"
              name='age'
              value={formData.age}
              onChange={handleInputChange}
              id='age'
            />
            {errors.age && <div className="error">{errors.age}</div>}
          </div>
        </div>
        <div className="form-row">
          <div className="patient-input1">
            <select
              className="patient-gender"
              name='gender'
              value={formData.gender}
              onChange={handleInputChange}
              id='gender'
            >
              <option value="" className="gender-color">Gender</option>
              <option value="Male">Male</option>
              <option value="Female">Female</option>
            </select>
            {errors.gender && <div className="error">{errors.gender}</div>}
          </div>
          <div className="patient-input1">
            <input
              type="text"
              placeholder="Mobile"
              name='mobile'
              value={formData.mobile}
              onChange={handleInputChange}
              id='mobile'
            />
            {errors.mobile && <div className="error">{errors.mobile}</div>}
          </div>
        </div>
        <div className="patient-input1">
          <textarea
            className="prescription-symptoms"
            placeholder="Symptoms"
            name='symptoms'
            value={formData.symptoms}
            onChange={handleInputChange}
            id='symptoms'
          />
          {errors.symptoms && <div className="error">{errors.symptoms}</div>}
        </div>
        <div className="patient-input1">
          <textarea
            className="prescription-treatment"
            placeholder="Treatment"
            name='treatment'
            value={formData.treatment}
            onChange={handleInputChange}
            id='treatment'
          />
          {errors.treatment && <div className="error">{errors.treatment}</div>}
        </div>
        <div className="policy1">
          <input
            type="checkbox"
            checked={acceptTerms}
            onChange={acceptTermsHandler}
            id="terms"
          />
          <label htmlFor="terms">I accept all terms & conditions</label>
        </div>
        {errors.acceptTerms && <div className="error">{errors.acceptTerms}</div>}
        <div className="button-container">
          <button type="submit" className="patientregisterbtn">Register Now</button>
        </div>
      </form>
    </div>
  );
};

export default Patientregistration;