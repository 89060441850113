import React from "react";
import "../css/patientdashboard.css";

const Patientdashboard = () => {
  return (
    <>
     
    </>
  );
};

export default Patientdashboard;