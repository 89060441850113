import '../css/patientdashboardheader.css'
import React, { useState } from 'react';


const Patientdashboardheader = () => {

  const [ispatientslidebarClosed, setIspatientslidebarClosed] = useState(false);
  

 

  const togglepatientslidebar = () => {
    setIspatientslidebarClosed(!ispatientslidebarClosed);
  };




  return (
    <>
    
  
    <div className="App">
      <nav className={`patientslidebar ${ispatientslidebarClosed ? 'close' : ''}`}>
        <header>
          <div className="image-text">
            <span className="image">
              <img src="logo.png" alt="" />
            </span>

            <div className="text logo-text">
              <span className="name">Medical</span>
              <span className="profession">Health Care</span>
            </div>
          </div>

          <i className='bx bx-chevron-right toggle' onClick={togglepatientslidebar}></i>
        </header>

        <div className="menu-bar">
          <div className="menu">
           

            <ul className="menu-links">
              <li className="nav-link">
                <a href="#">
                  <i className='bx bx-home-alt icon'></i>
                  <span className="text nav-text">Dashboard</span>
                </a>
              </li>

              <li className="nav-link">
                <a href="#">
                  <i className='bx bx-bar-chart-alt-2 icon'></i>
                  <span className="text nav-text">Report</span>
                </a>
              </li>

              {/* <li className="nav-link">
                <a href="#">
                  <i className='bx bx-bell icon'></i>
                  <span className="text nav-text">Notifications</span>
                </a>
              </li> */}

              <li className="nav-link">
                <a href="#">
                  <i className='bx bx-pie-chart-alt icon'></i>
                  <span className="text nav-text">Revenue</span>
                </a>
              </li>

              <li className="nav-link">
                <a href="#">
                  <i className='bx bx-user icon'></i>
                  <span className="text nav-text">Doctor Visit</span>
                </a>
              </li>

              <li className="nav-link">
                <a href="#">
                <i className='bx bx-log-out icon'></i>
                <span className="text nav-text">Logout</span>
                </a>
              </li>
            </ul>
          </div>

      
        </div>
      </nav>

     
    </div>
  
   
    </>
  )
}

export default Patientdashboardheader;
