import React, { useEffect, useState } from 'react';
import axios from 'axios';
import "../css/doctorpatientlist.css";

const Doctorpatientlist = () => {
  const [userlist, setuserlist] = useState([]);

  useEffect(() => {
    const id = localStorage.getItem('id');
    userlistData(id);
  }, []);

  const userlistData = (doctor_id) => {
    axios.get(`http://localhost:8080/auth/patientlist/${doctor_id}`)
      .then((response) => {
        setuserlist(response.data.message);
      })
      .catch((error) => {
        console.error("Error fetching patient list:", error);
      });
  };

  return (
    <div className="doctor-patient-list">
      <div className="table-container">
        <table className='patient-details'>
          <thead>
            <tr>
              <th>Patient Id</th>
              <th>Name</th>
              <th>Age</th>
              <th>Gender</th>
              <th>Mobile</th>
              <th>Symptoms</th>
              <th>Treatment</th>
            </tr>
          </thead>
          <tbody>
            {userlist && userlist.map((user) => (
              <tr  key={user.id}>
                <td data-label="Patient Id">{user.id}</td>
                <td data-label="Name">{user.name}</td>
                <td data-label="Age">{user.age}</td>
                <td data-label="Gender">{user.gender}</td>
                <td data-label="Mobile">{user.mobile}</td>
                <td data-label="Symptoms">{user.symptoms}</td>
                <td data-label="Treatment">{user.treatment}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Doctorpatientlist;





// .patientdoctor-details {

// }

// th, td {
//   padding: 8px;
//   text-align: left;
//   border-bottom: 1px solid #DDD;
//   width: 16%;
// }