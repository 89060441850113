import "../css/drdashboard.css";
import { useState } from "react";
import { FaStar } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const Drdashboard = () => {
  const navigate = useNavigate();

  return (
    <>
      <section class="main--content">
        <div class="overview">
          <div class="cards">
            <div class="card card-1">
              <div
                class="card--data"
                onClick={() => navigate("/doctorappointmentlist")}
              >
                <div class="card--content">
                  <h5 class="card--title">Appointment</h5>
                  <h1>152</h1>
                </div>
                <i className="ri-calendar-2-line"></i>
              </div>
              <div class="card--stats">
                <span>
                  <i class="ri-bar-chart-fill card--icon stat--icon"></i>65%
                </span>
                <span>
                  <i class="ri-arrow-up-s-fill card--icon up--arrow"></i>10
                </span>
                <span>
                  <i class="ri-arrow-down-s-fill card--icon down--arrow"></i>2
                </span>
              </div>
            </div>
            <div class="card card-2">
              <div
                class="card--data"
                onClick={() => navigate("/doctorpatientlist")}
              >
                <div class="card--content">
                  <h5 class="card--title">Total Patients</h5>
                  <h1>1145</h1>
                </div>
                <i class="ri-user-line card--icon--lg"></i>
              </div>
              <div class="card--stats">
                <span>
                  <i class="ri-bar-chart-fill card--icon stat--icon"></i>82%
                </span>
                <span>
                  <i class="ri-arrow-up-s-fill card--icon up--arrow"></i>230
                </span>
                <span>
                  <i class="ri-arrow-down-s-fill card--icon down--arrow"></i>45
                </span>
              </div>
            </div>

            <div class="card card-4">
              <div class="card--data">
                <div class="card--content">
                  <h5 class="card--title">Beds Available</h5>
                  <h1>15</h1>
                </div>
                <i class="ri-hotel-bed-line card--icon--lg"></i>
              </div>
              <div class="card--stats">
                <span>
                  <i class="ri-bar-chart-fill card--icon stat--icon"></i>8%
                </span>
                <span>
                  <i class="ri-arrow-up-s-fill card--icon up--arrow"></i>11
                </span>
                <span>
                  <i class="ri-arrow-down-s-fill card--icon down--arrow"></i>2
                </span>
              </div>
            </div>
            <div class="card card-3">
              <div class="card--data">
                <div class="card--content">
                  <h5 class="card--title">Payments</h5>
                  <h1>102</h1>
                </div>
                <i class="ri-calendar-2-line card--icon--lg"></i>
              </div>
              <div class="card--stats">
                <span>
                  <i class="ri-bar-chart-fill card--icon stat--icon"></i>27%
                </span>
                <span>
                  <i class="ri-arrow-up-s-fill card--icon up--arrow"></i>31
                </span>
                <span>
                  <i class="ri-arrow-down-s-fill card--icon down--arrow"></i>23
                </span>
              </div>
            </div>
          </div>
        </div>
      </section>

     
    </>
  );
};

export default Drdashboard;








// <div className="dashboard-main-content">
// <div className="row">
//   <div className="col">
//     <div className="graph-chart">
//       <h2 className="chart-heading">Patients Summary</h2>
//       <div className="donut-chart">
//         <div className="slice total"></div>
//         <div className="slice new"></div>
//         <div className="slice old"></div>
//         <div className="chart-center"></div>
//       </div>
//       <div className="chart-patient-paragrapgh">
//         <div className="chart-patient-paragrapgh-item">
//           <span className="color-box new"></span>
//           <span>New Patients</span>
//         </div>
//         <div className="chart-patient-paragrapgh-item">
//           <span className="color-box old"></span>
//           <span>Old Patients</span>
//         </div>
//         <div className="chart-patient-paragrapgh-item">
//           <span className="color-box total"></span>
//           <span>Total Patients</span>
//         </div>
//       </div>
//     </div>
//   </div>

//   <div className="col">
//     <div className="patient-review-container">
//       <div className="review-type">
//         <h3>Patients Review</h3>
//       </div>
//       <div className="review-items">
//         <div className="review-item">
//           <div className="review-label">Excellent</div>
//           <div className="review-bar">
//             <div
//               className="review-bar-fill"
//               style={{ width: "90%", background: "#007bff" }}
//             ></div>
//           </div>
//         </div>
//         <div className="review-item">
//           <div className="review-label">Great</div>
//           <div className="review-bar">
//             <div
//               className="review-bar-fill"
//               style={{ width: "80%", background: "#28a745" }}
//             ></div>
//           </div>
//         </div>
//         <div className="review-item">
//           <div className="review-label">Good</div>
//           <div className="review-bar">
//             <div
//               className="review-bar-fill"
//               style={{ width: "70%", background: "#ffc107" }}
//             ></div>
//           </div>
//         </div>
//         <div className="review-item">
//           <div className="review-label">Average</div>
//           <div className="review-bar">
//             <div
//               className="review-bar-fill"
//               style={{ width: "40%", background: "#53dada " }}
//             ></div>
//           </div>
//         </div>
//         <div className="review-item">
//           <div className="review-label">Bad </div>
//           <div className="review-bar">
//             <div
//               className="review-bar-fill"
//               style={{ width: "20%", background: "#f46464" }}
//             ></div>
//           </div>
//         </div>
//       </div>
//     </div>
//   </div>
// </div>
// </div>















/* 

  
import { FaStar } from "react-icons/fa";

  const colors = {
    orange: "#FFBA5A",
    grey: "#a9a9a9",
  };

  const [currentValue, setCurrentValue] = useState(0);
  const [hoverValue, setHoverValue] = useState(undefined);
  const stars = Array(5).fill(0);
  const [name, setname] = useState("");

  const handleClick = (value) => {
    setCurrentValue(value);
  };

  const handleMouseOver = (newHoverValue) => {
    setHoverValue(newHoverValue);
  };

  const handleMouseLeave = () => {
    setHoverValue(undefined);
  };
  const nameHandler = (event) => {
    setname(event.target.value);
  };

  const reviewhandle = () => {
    let reviewdata = {};
  };

       <form onSubmit={reviewhandle}>
          <div className="review">
            <h2> Share Your Reviews </h2>
            <div className="stars">
              {stars.map((_, index) => {
                return (
                  <FaStar
                    key={index}
                    size={24}
                    onClick={() => handleClick(index + 1)}
                    onMouseOver={() => handleMouseOver(index + 1)}
                    onMouseLeave={handleMouseLeave}
                    color={
                      (hoverValue || currentValue) > index
                        ? colors.orange
                        : colors.grey
                    }
                    style={{
                      marginRight: 10,
                      cursor: "pointer",
                    }}
                  />
                );
              })}
            </div>
            <input className="namereview" type="text" placeholder="Name" name="name" onChange={nameHandler}
            id="name" data-required="true" data-error-message="Your Name Is Required"/>

            <textarea className="textarea" placeholder="What's your experience?" />

            <button className="reviewsubmit">Submit</button>
          </div>
          </form> */
