
import React, { useState } from "react";
import "../css/dashboard.css";
import dashimg from "./image/dashimg.png";
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import Swal from 'sweetalert2';

const Dashboard = () => {
  const navigate = useNavigate();
  
  const [doctor_mobile, doctor_mobileSet] = useState('');
  const [password, passwordSet] = useState('');
  const [newPassword, newPasswordSet] = useState('');
  const [errors, setErrors] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [isResettingPassword, setIsResettingPassword] = useState(false);

  const doctormobileHandler = (event) => {
    doctor_mobileSet(event.target.value);
  }
  
  const passwordHandler = (event) => {
    passwordSet(event.target.value);
  }

  const newPasswordHandler = (event) => {
    newPasswordSet(event.target.value);
  }

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  }

  const validateForm = () => {
    let formErrors = {};

    if (!doctor_mobile) {
      formErrors.doctor_mobile = "Mobile number is required";
    } else if (!/^\d{10}$/.test(doctor_mobile)) {
      formErrors.doctor_mobile = "Please enter a valid 10-digit mobile number";
    }

    if (!isResettingPassword && !password) {
      formErrors.password = "Password is required";
    } else if (isResettingPassword && !newPassword) {
      formErrors.newPassword = "New password is required";
    } else if ((isResettingPassword ? newPassword : password).length < 4) {
      formErrors[isResettingPassword ? 'newPassword' : 'password'] = "Password must be at least 4 characters long";
    }

    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  }

  const submitHandler = (event) => {
    event.preventDefault();
    
    if (validateForm()) {
      let data = { doctor_mobile: doctor_mobile };
      if (isResettingPassword) {
        data.newPassword = newPassword;
      } else {
        data.password = password;
      }

      const url = isResettingPassword ? 'http://localhost:8080/doctor/resetpassword/' : 'http://localhost:8080/doctor/doctorlogin/';

      axios.post(url, data).then((response) => {
          if (response.data.status === 200) {
            if (isResettingPassword) {
              Swal.fire({
                icon: "success",
                title: "Success",
                text: "Password reset successfully. Please login with your new password.",
              });
              setIsResettingPassword(false);
              newPasswordSet('');
            } else {
              localStorage.setItem('id', response.data.message.doctordata[0].id);
              localStorage.setItem('doctor_mobile', response.data.message.doctordata[0].doctor_mobile);
              localStorage.setItem('doctor_name', response.data.message.doctordata[0].doctor_name);
              localStorage.setItem('doctor_email', response.data.message.doctordata[0].doctor_email);
              
              navigate('/drdashboard');
            }
            doctor_mobileSet('');
            passwordSet('');
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: response.data.message,
            });
          }
        })
      .catch((error) => {
      console.error("Login error:", error);

      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Login failed. Please check your credentials and try again.",
        
      });
    });
    }
  }
  return (
    <div className="dashboard-container">
      <form name="loginform" onSubmit={submitHandler}>
        <div className="loginbox">
          <div className="formbox">
            <h3>{isResettingPassword ? "Reset Password" : "Login to Doctor"}</h3>
            <div className="input-field">
              <i className="fa fa-mobile" aria-hidden="true"></i>
              <input 
                type="text" 
                placeholder="Enter your mobile" 
                name="doctor_mobile" 
                onChange={doctormobileHandler}
                id="doctor_mobile" 
                value={doctor_mobile}
              />
              {errors.doctor_mobile && <div className="error">{errors.doctor_mobile}</div>}
            </div>
            {!isResettingPassword ? (
              <div className="input-field">
                <i className="fa fa-lock" aria-hidden="true"></i>
                <input 
                  type={showPassword ? "text" : "password"}
                  placeholder="Enter your password" 
                  name="password" 
                  onChange={passwordHandler}
                  id="password" 
                  value={password}
                />
                <i 
                  className={`fa ${showPassword ? "fa-eye-slash" : "fa-eye"}`}
                  aria-hidden="true"
                  onClick={togglePasswordVisibility}
                ></i>
                {errors.password && <div className="error">{errors.password}</div>}
              </div>
            ) : (
              <div className="input-field">
                <i className="fa fa-lock" aria-hidden="true"></i>
                <input 
                  type={showPassword ? "text" : "password"}
                  placeholder="Enter new password" 
                  name="newPassword" 
                  onChange={newPasswordHandler}
                  id="newPassword" 
                  value={newPassword}
                />
                <i 
                  className={`fa ${showPassword ? "fa-eye-slash" : "fa-eye"}`}
                  aria-hidden="true"
                  onClick={togglePasswordVisibility}
                ></i>
                {errors.newPassword && <div className="error">{errors.newPassword}</div>}
              </div>
            )}
            <a className="drforgot" onClick={() => setIsResettingPassword(!isResettingPassword)}>
              {isResettingPassword ? "Back to Login" : "Forgot password ?"}
            </a>
            <div className="drsignup">
              Don't have an account ? <a onClick={() => navigate('/drregistration')}>Signup</a>
            </div>
            <section>
              <button className="loginbtn" type="submit">{isResettingPassword ? "Reset Password" : "Login"}</button>
            </section>
            {errors.form && <div className="error">{errors.form}</div>}
            <div className="or">Or</div>
            <section>
              <button className="patient" type="button" onClick={() => navigate('/patient')}>As a Patient</button>
            </section>
          </div>
          <div className="loginimg">
            <img src={dashimg} alt="" />
          </div>
        </div>
      </form>
    </div>
  );
};

export default Dashboard;